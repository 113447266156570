import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from 'layouts/zh'
import { FinanceConsumerState } from '../finance/index.zh'

const FinanceManage: React.FC = (props) => {
  const { financeYaml } = useStaticQuery(graphql`
    query {
      financeYaml(page_name: { eq: "理财" }) {
        title
        description
        keywords
        saTitle
        tabs {
          name
          url
          isActive
        }
        banner_title
        banner_title_m
        banner_desc
        banner_iconMB
        banner_btnText
        banner_btnHref
        banner_bg_pc
        banner_icon
        banner_icon_mb
        banner_bg_mb
        section_two_operationTitle
        section_two_operationTitleMb
        section_two_operationDesc
        section_two_mobileButtonText
        section_two_mobileButtonLink
        section_two_dataList {
          buttonText
          buttonHref
          descList
          dataTitle
          imgUrl
        }
        section_three_title
        section_three_desc
        section_three_buttonText
        section_three_qrcode
        section_three_cardList {
          desc
          title
          cover
        }
        panorama_title
        panorama_titleMb
        panorama_desc
        panorama_btnText
        panorama_btnCode
        panorama_imgUrl
        customer_title
        customer_desc
        partner_dataList {
          imgUrl
        }
        partner_buttonText
        partner_qrcode
        cargo_title
        cargo_titleMb
        cargo_dataList {
          btnHref
          btnText
          dataMsg
          dataTitle
          imgUrl
          imgUrlMb
        }
        product_title
        product_titleMb
        product_dataList {
          btnHref
          btnText
          dataDsc
          dataMsg
          dataTitle
          imgUrl
          imgUrlMb
        }
        digitization_btnHref
        digitization_btnText
        digitization_desc
        digitization_orderHref
        digitization_orderText
        digitization_title
      }
    }
  `)
  return (
    <Layout {...props}>
      <FinanceConsumerState data={financeYaml} />
    </Layout>
  )
}

export default FinanceManage
